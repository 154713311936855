<template>
    <div class="content-box">
        <van-sticky>
            <title-bar2 title="资料打印邮寄" />
        </van-sticky>
        <div class="content-flow">
            <van-image fit="contain" :src="require('@/assets/images/mt/flow.png')" />
        </div>
        <van-cell-group title="① 收件信息">
            <van-field label="收件姓名：" placeholder="请输入收件人姓名" v-model="recvName" />
            <van-field label="收件电话：" placeholder="请输入收件人电话" v-model="recvPhone" />
            <van-field label="收件地址：" placeholder="请输入收件人地址" v-model="recvAddress" />
            <van-cell>
                <van-button round block color="#FE5E03" @click="onSave">保存收件信息</van-button>
            </van-cell>
        </van-cell-group>
        <van-cell-group title="② 打印码">
            <van-cell title="" :label="documentName" />
            <van-cell title="" :label="printCode" />
            <van-cell>
                <van-button round block color="#FE5E03" @click="onCopy">复制打印信息</van-button>
            </van-cell>
        </van-cell-group>
        <van-cell-group title="③ 联系打印店">
            <div v-if="showPrinterQr" style="text-align:center">
                <van-image width="200" height="200" fit="contain" :src="printerQrImg" />
            </div>
            <van-cell v-else value="请联系卖家索取第三方打印店联系方式及其它相关事宜。" />
        </van-cell-group>
    </div>
</template>

<script>
import TitleBar2 from "@/components/MT/TitleBar2";
import Tools from "@/tool";
import Config from "@/api/service";
import Manager from "@/manager";

export default {
    name: "MTModule",
    components: {
        TitleBar2,
    },
    data() {
        return {
            recvName: null,
            recvPhone: null,
            recvAddress: null,
            printGuid: null,
            printCode: null,
            documentGuid: null,
            documentName: null,
            documentPageCount: null,
            showPrinterQr: false,
        };
    },
    computed: {
        printerQrImg() {
            if (this.showPrinterQr) {
                return Config.proxy.image + `?ProxyCode=${this.$store.state.proxyCode}&ImageIdentity=5`;
            }
        },
    },
    methods: {
        getRoutParams() {
            this.documentGuid = this.$route.query.documentGuid;
        },
        getProxyInfo() {
            var model = {
                ProxyCode: this.$store.state.proxyCode,
            };
            return this.$axios
                .post(Config.proxy.printerQrCodeVisible, model)
                .then((res) => {
                    this.showPrinterQr = res.data.Data.ShowPrinterQrCode;
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        getPrintInfo() {
            var model = {
                DocumentGuid: this.documentGuid,
            };
            this.$axios
                .post(Config.document.print.info, model)
                .then((res) => {
                    let data = res.data.Data;
                    this.printGuid = data.PrintGuid;
                    this.printCode = data.PrintCode;
                    this.documentName = data.DocumentName;
                    this.documentPageCount = data.DocumentPageCount;
                    var array = data.PrintConsigneeInfo.split("\n");
                    if (array.length > 0) {
                        this.recvName = array[0].replace("收件姓名：", "");
                    }
                    if (array.length > 1) {
                        this.recvPhone = array[1].replace("收件电话：", "");
                    }
                    if (array.length > 2) {
                        this.recvAddress = array[2].replace("收件地址：", "");
                    }
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        addVisitSchoolRecord() {
            Manager.addVisitSchoolRecord({
                DocumentGuid: this.documentGuid,
                StudentPhone: this.recvPhone,
                RecordModule: 5,
            });
        },
        onSave() {
            if (!Tools.checkPhone(this.recvPhone)) {
                this.$toast.fail("请输入正确的收件电话。");
                return;
            }
            var model = {
                PrintGuid: this.printGuid,
                PrintConsigneeInfo:
                    "收件姓名：" +
                    this.recvName +
                    "\n收件电话：" +
                    this.recvPhone +
                    "\n收件地址：" +
                    this.recvAddress,
            };
            this.$axios
                .post(Config.document.print.update, model)
                .then((res) => {
                    this.addVisitSchoolRecord();
                    this.$toast.success("收件信息保存成功。");
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        onCopy() {
            try {
                let that = this;
                var content =
                    "打印码：" +
                    this.printCode +
                    "\n资料名：" +
                    this.documentName +
                    "\n总页数：" +
                    this.documentPageCount +
                    "\n收件姓名：" +
                    this.recvName +
                    "\n收件电话：" +
                    this.recvPhone +
                    "\n收件地址：" +
                    this.recvAddress;
                this.$copyText(content).then(
                    function (e) {
                        that.$message.success("打印信息复制成功。");
                    },
                    function (e) {
                        that.$message.error("打印信息复制失败。" + e.message);
                    }
                );
            } catch (error) {
                this.$dialog({ title: "错误", message: error.message });
            }
        },
    },
    mounted() {
        this.getRoutParams();
        this.getProxyInfo();
        this.getPrintInfo();
    }
};
</script>
<style scoped>
.content-box {
    background-color: white;
}

.content-flow {
    padding: 10px;
    text-align: center;
}

.van-cell-group__title {
    padding: 10px 16px 8px;
}

.van-cell {
    padding: 5px 16px;
}
</style>