<template>
    <div class="content-box">
        <div class="content-title">
            <header-entry>资料打印</header-entry>
        </div>
        <div class="content-flow">
            <span class="content-flow-item">填写收件信息</span>
            <img class="content-flow-arrow" src="@/assets/images/pc/arrow.png" />
            <span class="content-flow-item">复制打印码</span>
            <img class="content-flow-arrow" src="@/assets/images/pc/arrow.png" />
            <span class="content-flow-item">联系打印店</span>
            <img class="content-flow-arrow" src="@/assets/images/pc/arrow.png" />
            <span class="content-flow-item">邮寄到家</span>
        </div>
        <div class="content-header">
            ① 收件信息
        </div>
        <div class="content-item">
            <div class="content-item-text">收件姓名：</div>
            <el-input class="content-item-input" placeholder="请输入收件人姓名" v-model="recvName"></el-input>
        </div>
        <div class="content-item">
            <div class="content-item-text">收件电话：</div>
            <el-input class="content-item-input" placeholder="请输入收件人电话" v-model="recvPhone"></el-input>
        </div>
        <div class="content-item">
            <div class="content-item-text">收件地址：</div>
            <el-input class="content-item-input" placeholder="请输入收件人地址" v-model="recvAddress"></el-input>
        </div>
        <div class="content-item">
            <el-button type="primary" @click="onSave">保存收件信息</el-button>
        </div>
        <div class="content-line"></div>
        <div class="content-header">
            ② 打印码
        </div>
        <div class="content-item">
            <div class="content-item-text">资料名称：</div>
            <el-input class="content-item-input" disabled v-model="documentName"></el-input>
        </div>
        <div class="content-item">
            <div class="content-item-text">打印编码：</div>
            <el-input class="content-item-input" disabled v-model="printCode"></el-input>
        </div>
        <div class="content-item">
            <el-button type="primary" @click="onCopy">复制打印编码</el-button>
        </div>
        <div class="content-line"></div>
        <div class="content-header">
            ③ 联系打印店
        </div>
        <div class="content-item">
            <img v-if="showPrinterQr" :src="printerQrImg" class="content-img" />
            <van-cell v-else value="请联系卖家索取第三方打印店联系方式及其它相关事宜。" />
        </div>
        <div class="content-line"></div>
    </div>
</template>

<script>
import HeaderEntry from "@/components/PC/HeaderEntry";
import Tools from "@/tool";
import Config from "@/api/service";
import Manager from "@/manager";

export default {
    name: "PCModule",
    components: {
        HeaderEntry,
    },
    data() {
        return {
            recvName: null,
            recvPhone: null,
            recvAddress: null,
            printGuid: null,
            printCode: null,
            documentGuid: null,
            documentName: null,
            documentPageCount: null,
            showPrinterQr: false,
        };
    },
    computed: {
        printerQrImg() {
            return Config.proxy.image + `?ProxyCode=${this.$store.state.proxyCode}&ImageIdentity=5`;
        },
    },
    methods: {
        getRoutParams() {
            this.documentGuid = this.$route.query.documentGuid;
        },
        getProxyInfo() {
            var model = {
                ProxyCode: this.$store.state.proxyCode,
            };
            return this.$axios
                .post(Config.proxy.printerQrCodeVisible, model)
                .then((res) => {
                    this.showPrinterQr = res.data.Data.ShowPrinterQrCode;
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
        },
        getPrintInfo() {
            var model = {
                DocumentGuid: this.documentGuid
            };
            this.$axios
                .post(Config.document.print.info, model)
                .then((res) => {
                    let data = res.data.Data;
                    this.printGuid = data.PrintGuid;
                    this.printCode = data.PrintCode;
                    this.documentName = data.DocumentName;
                    this.documentPageCount = data.DocumentPageCount;
                    var array = data.PrintConsigneeInfo.split("\n");
                    if (array.length > 0) {
                        this.recvName = array[0].replace("收件姓名：", "");
                    }
                    if (array.length > 1) {
                        this.recvPhone = array[1].replace("收件电话：", "");
                    }
                    if (array.length > 2) {
                        this.recvAddress = array[2].replace("收件地址：", "");
                    }
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
        },
        addVisitSchoolRecord() {
            Manager.addVisitSchoolRecord({
                DocumentGuid: this.documentGuid,
                StudentPhone: this.recvPhone,
                RecordModule: 5,
            });
        },
        onSave() {
            if (!Tools.checkPhone(this.recvPhone)) {
                this.$message.warning("请输入正确的收件电话。");
                return;
            }
            var model = {
                PrintGuid: this.printGuid,
                PrintConsigneeInfo:
                    "收件姓名：" +
                    this.recvName +
                    "\n收件电话：" +
                    this.recvPhone +
                    "\n收件地址：" +
                    this.recvAddress,
            };
            this.$axios
                .post(Config.document.print.update, model)
                .then((res) => {
                    this.addVisitSchoolRecord();
                    this.$message.success("收件信息保存成功。");
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
        },
        onCopy() {
            try {
                let that = this;
                var content =
                    "打印码：" +
                    this.printCode +
                    "\n资料名：" +
                    this.documentName +
                    "\n总页数：" +
                    this.documentPageCount +
                    "\n收件姓名：" +
                    this.recvName +
                    "\n收件电话：" +
                    this.recvPhone +
                    "\n收件地址：" +
                    this.recvAddress;
                this.$copyText(content).then(
                    function (e) {
                        that.$message.success("打印信息复制成功。");
                    },
                    function (e) {
                        that.$message.error("打印信息复制失败。" + e.message);
                    }
                );
            } catch (error) {
                this.$message.error(error.message);
            }
        },
    },
    mounted() {
        this.getRoutParams();
        this.getProxyInfo();
        this.getPrintInfo();
    }
};
</script>
<style scoped>
.content-box {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.content-flow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 20px;
}

.content-flow-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FE5E03;
    width: 20px;
    margin: 0 10px;
}

.content-flow-item {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #515151;
    font-size: 14px;
    padding: 5px 15px;
    border: 1px solid #FE5E03;
    border-radius: 2px;
}

.content-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #515151;
    font-size: 18px;
    margin: 20px 0;
}

.content-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0;
}

.content-item-text {
    color: gray;
    font-size: 14px;
}

.content-item-input {
    width: 500px;
}

.content-img {
    width: 200px;
    width: 200px;
}

.content-line {
    border-top: 1px solid #e6e6e6;
    margin: 10px 0;
}
</style>